<template>
    <v-chip-group>
        <v-chip x-small v-for="(day, index) in daysOfWeek" :key="index" :color="days.indexOf(index) !== -1 ? 'primary': 'grey lighten-3'">
            {{ day }}
        </v-chip>
    </v-chip-group>
</template>

<script>
export default {
    props: {
        days: {
            type: String,
            required: true
        }
    },

    data: () => ({
        daysOfWeek: [
            'L', 'M', 'M', 'G', 'V', 'S', 'D'
        ]
    })
}
</script>

<style>

</style>
